<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a
            @click="back"
            class="btn p-0 mr-md-4 text-light h5 mb-0"
          >
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Produk Info</span>
          </span>
          <div class="text-nowrap order-md-last">
            <a id="shareBtn" class="btn px-0 d-inline-block mr-3" @click="shareData">
              <i class="fa fa-share-alt text-light"></i>
            </a>
            <a
              @click="deleteRelasi(state.data.id)"
              class="btn px-0 d-inline-block"
            >
              <i class="fa fa-trash text-light"></i>
            </a>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header" v-if="state.finish">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="'background-image: url('+state.url + state.data.produk.photo+');'"
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.data.produk.name }}</p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <p class="mb-1 small text-muted">Harga Jual Satuan</p>
              <p class="mb-0 text-main currency">Rp. {{ changeMoney(Number(state.data.produk.price) + Number(state.data.margin)) }}</p>
            </div>
            <!-- .col -->
            <div class="col-6 text-right text-md-left">
              <p class="mb-1 small text-muted">Diskon</p>
              <p class="mb-0">-</p>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <div class="row mt-3">
            <div class="col-6">
              <p class="mb-1 small text-muted">Harga Modal</p>
              <p class="mb-0 currency">Rp. {{ changeMoney(state.data.produk.price) }}</p>
            </div>
            <!-- .col -->
            <div class="col-6 text-right text-md-left">
              <p class="mb-1 small text-muted">Nilai Margin</p>
              <p class="mb-0 currency">Rp. {{ changeMoney(state.data.margin) }}</p>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <div class="row mt-3">
            <div class="col-12">
              <p class="mb-1 small text-muted">Deskripsi</p>
              <p class="mb-0">{{ state.data.produk.desc }}</p>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
          <div class="input-group input-group-sm my-1">
            <span class="small pt-2 mr-2">Margin: </span>
            <currency-input
              class="form-control currency"
              v-model="state.margin"
              :options="{ currency: 'IDR' }"
              name="price"
            />
            <div class="input-group-append">
              <a
                @click="updateRelasi()"
                class="saveProduct input-group-text bg-transparent mr-2"
                >Update</a
              >
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 px-0">
              <ul class="list-group rounded-0 mt-3">
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <a
                    @click="deleteRelasi(state.data.id)"
                    class="py-2 pl-3 text-uppercase stretched-link"
                    >Hapus Dari Produk Grup</a
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
              </ul>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useVueSweetAlert2 } from "../../../../useVueSweetAlert2.js";
import CurrencyInput from "../../../../helpers/CurrencyInput.vue";
import Share from '../../../../components/social_media/Share.js';
import imageDefault from '../../../../assets/img/img.png';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const swal = useVueSweetAlert2();
    const state = reactive({
      id: "",
      data: {},
      url: process.env.VUE_APP_API_URL,
      finish: false,
      group_id: '',
      margin: 0,
    });
    const loadData = async () => {
      await axios
        .get("/api/seller/produk-group/relasiProduk/" + state.id)
        .then((res) => {
          if (res.data.success) {
            state.data = res.data.data;
            state.margin = Number(res.data.data.margin);
            state.finish = true;
            state.group_id = res.data.data.group_id;
          }
        });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    });
    const deleteRelasi = (produk_id) => {
      swal
        .fire({
          text: "Apakah Anda yakin Akan Menghapus Produk Ini?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya",
          cancelButtonText: "Tidak",
          confirmButtonColor: "#b50b1b",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete("/api/seller/produk-group/relasiProduk/" + produk_id)
              .then((res) => {
                if (res.data.success) {
                  swal.fire({
                    icon: "success",
                    title: "Info",
                    text: "Produk Berhasil Di Hapus!",
                  });
                }
                router.push({path: '/jual/produk-group/detail/'+state.group_id });
              });
          }
        });
    };
    const changeMoney = (dt) => {
      let cur = Math.ceil(Number(dt));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];
      return ret;
    };
    const updateRelasi = () => {
      //update margin
      if (state.margin >= 0) {
        const form = state.data;
        form.margin = state.margin;
        axios
          .post("/api/seller/produk-group/addProduk", form)
          .then((res) => {
            if (res.data.success) {
              swal.fire({
                icon: "success",
                title: "Selamat",
                text: "Margin Berhasil DiRubah!",
              });
            }
            loadData();
        });
      }else{
        swal.fire("", "Margin Harus lebih dari >= 0");
      }
    }
    const shareData = async () => {
      let dt = {
        title: state.data.name,
        url: process.env.VUE_APP_HOME_URL + '/home/produk/'+state.data.produk.key,
        description: '',
      }
      Share.run('whatsapp', dt);
    }
    const isPhoto = (dt) => {
        if(dt){
            return state.url + dt;
        }else {
            return imageDefault;
        }
    }
    const back = () => {
      window.history.back();
    }
    return {
      state,
      deleteRelasi,
      changeMoney,
      updateRelasi,
      shareData,
      isPhoto,
      back
    };
  },
  components: {
    CurrencyInput,
  }
};
</script>